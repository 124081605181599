import React from 'react'
import PropTypes from 'prop-types'

import '../assets/scss/main.scss'
import Header from './Header'
import Footer from './Footer'
import Helmet from 'react-helmet'

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: 'is-loading'
        }
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render() {
        const { children } = this.props

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
              <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-90496362-1"></script>
                <script>
                  {
                    `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', 'UA-90496362-1');
                    `
                  }
                </script>
              </Helmet>
                <div id="wrapper">
                    <Header />
                    {children}
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Layout
