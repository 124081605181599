import React from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/images/hiredevs-logo-hover.png'
import styled from 'styled-components'

import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";
import Popup from "reactjs-popup";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const LogoImg = styled.img`
  width: 200px;
  height: 36px;
`

const contentStyle = {
  background: "black",
  width: "80%",
  border: "none"
};

const MobileLinks = styled.span`
  @media screen and (min-width: 736px) {  
    display: none;
  }
  @media screen and (max-width: 736px) {  
    display: block;
  }
  
  ul {
    display: block;
    
    li {
      list-style: none;
    }
  }
`
const Links = styled.span`
  @media screen and (min-width: 736px) {  
    display: block;
  }
  @media screen and (max-width: 736px) {  
    display: none;
  }
  
  ul {
    display: inline-flex;
    
    li {
      list-style: none;
      margin-right: 1em;
    }
  }
`



const Header = (props) => (
    <header id="header" className="alt">
        <div className="inner">
          <HeaderWrapper>
            <Link to="/" className="logo"><LogoImg src={Logo} alt="Hire Devs logo" /></Link>
            <Links>
              <Menu />
            </Links>
            <MobileLinks>
              <Popup
                modal
                overlayStyle={{ background: 'black' }}
                contentStyle={contentStyle}
                closeOnDocumentClick={true}
                trigger={open => <BurgerIcon open={open} />}
              >
                {close => <Menu close={close} />}
              </Popup>
            </MobileLinks>
          </HeaderWrapper>
        </div>
    </header>
)

export default Header
