import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'

const NavMenu = styled.div`
  a {
    text-decoration: none;
    border-bottom: none;
  }
`

const Menu = ({ close }) => (
  <NavMenu className="menu">
    <ul>
      <li><AnchorLink onClick={close} href="#about">About</AnchorLink></li>
      <li><AnchorLink onClick={close} href="#services">Services</AnchorLink></li>
      <li><AnchorLink onClick={close} href="#contact">Submit Project</AnchorLink></li>
    </ul>
  </NavMenu>
);

export default Menu;
