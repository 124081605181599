import React from 'react'
import SimpleLineIcon from 'react-simple-line-icons';
import styled from 'styled-components';

const ScrollTop = styled.div`
  
`
const Wrapper = styled.div`
   display: flex;
   justify-content: space-between;
`

const getYear = () => {
  const date = new Date();
  return date.getFullYear()
}


const backToTopClicked = (e) => {
  e.preventDefault();
  // document.body.scrollTop = 0; // For Safari
  // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

}

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <Wrapper>
              {/*<ul className="icons">*/}
                  {/*<li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>*/}
                  {/*<li><a href="#" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>*/}
                  {/*<li><a href="#" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>*/}
                  {/*<li><a href="#" className="icon alt fa-github"><span className="label">GitHub</span></a></li>*/}
                  {/*<li><a href="#" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>*/}
              {/*</ul>*/}
              <ul className="copyright">
                  <li>&copy; {getYear()} Hire Devs, LLC - All Rights Reserved</li>
              </ul>
              <ScrollTop><a href="" onClick={backToTopClicked}><SimpleLineIcon size="large" name="arrow-up" /></a></ScrollTop>
            </Wrapper>
        </div>
    </footer>
)

export default Footer
